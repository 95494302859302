.deal-header {
  width: 100%;
  height: $mobile-nav-height;
  z-index: $layer-fixedbars;
  background: $white;
  position: fixed;
  top: 0;
  display: flex;

  @include mediaquery-medium {
    top: $top-bar-height-slim;
    height: $deal-header-height;
  }

  /*
    Read Only Deal version
  */
  &.readonly {
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0px 5px 10px rgba(37, 47, 61, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px 10px 70px;

    @include mediaquery-medium {
      padding-left: 12px;
    }
  }

  .pdf-header,
  .previous-header {
    position: absolute;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 40px;
    right: 40px;

    @include mediaquery-medium {
      left: 350px;
      right: 350px;
    }

    .pdf-name,
    .deal-name {
      font-weight: 600;
      font-size: 1.4rem;
      &.editable {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .page-count,
    .previous-version-info {
      font-size: 1.2rem;
      color: $black40;
    }
  }

  // TEMP 3PP
  // This should be a proper <Toolbar> + <Toolbar.Group> + <Toolbar.Button> component and styling.
  // It's extremely similar and it's hardcoded on both template and dealview sides ...

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0px 5px 10px rgba(37, 47, 61, 0.05);
    background: inherit;

    .toolbar-section {
      display: flex;
      background: inherit;
      align-items: center;

      &.external-tools {
        width: 100%;
        border-right: 0;
        top: 60px;
        position: absolute;
        height: auto;
        border-bottom: 1px solid $black10;
        box-shadow: $toolbar-box-shadow;
        height: 41px;

        @include mediaquery-medium {
          top: initial;
          height: auto;
          width: auto;
          position: relative;
          border-bottom: none;
          box-shadow: none;
        }

        .spacer {
          flex: 1;
        }

        .zoom {
          align-self: center;
        }

        .pdf-controls {
          .btn-tool {
            margin: 0px 5px;
          }
        }
      }
    }

    .toolbar-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e5e5e5;
      padding: 0 5px;

      &.right {
        border-left: 1px solid #e5e5e5;
        border-right: 0;
      }

      &.no-border {
        border: 0;
      }
    }

    .btn-pdf-download {
      margin: 0 12px;
    }

    .btn-bundle {
      margin-left: 15px;
      margin-right: -5px;
    }

    .btn-tool .dmp-dd-toggle {
      width: 100%;
      height: 100%;
    }

    // DealStatusSelector in header is link style and needs extra left padding to align swatch in the toggle with that of the list items
    .dd-deal-status .dmp-dd-toggle {
      margin-left: 4px;
    }

    .btn-export {
      margin: 0 10px;
      width: auto;
      svg {
        margin-right: 5px;
      }
    }
  }

  .deal-status {
    .deal-status-display {
      text-decoration: none;
      padding-right: 15px;
      cursor: default;
      display: flex;
      align-items: center;
    }
  }

  .label-readonly {
    display: flex;
    align-items: center;

    color: $black60;
    font-size: 1.2rem;
    font-weight: 600;
    span {
      margin-left: 5px;
    }
    svg {
      width: 20px;
      height: 20px;

      g {
        stroke: $black30;
      }
      path {
        fill: $black60;
      }
    }
  }

  .mobile-sub {
    position: fixed;
    display: flex;
    flex-direction: row;
    top: $mobile-nav-height;
    height: $mobile-abc-height;
    width: 100%;
    background: $white;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0px 5px 10px rgba(37, 47, 61, 0.05);

    .btn-bundle {
      margin-right: 0px;
    }
  }

  .abc {
    display: flex;
    flex: 1;
    min-width: 0;

    .abc-dealname {
      white-space: nowrap;
      margin-left: 15px;
      margin-right: 15px;
      flex: 1;
      align-self: center;
    }

    @include mediaquery-medium {
      position: relative;
      top: 0;
      height: 100%;
      background: none;
      border: none;
      box-shadow: none;
      padding-right: 20px;
    }

    .steps {
      position: relative;
      z-index: 2;
      height: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: width 0.5s;
      margin-right: 15px;

      @include mediaquery-medium {
        justify-content: flex-start;
        width: auto;
        margin-left: 20px;
      }
      .step {
        &:not(:last-child) {
          margin-right: 10px;
          @include mediaquery-medium {
            margin-right: 20px;
          }
        }
        cursor: default;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;

        //if the step is active, enable the mouse cursor
        //so the user can access the tooltip
        &.active,
        &.activate {
          cursor: pointer;
        }

        &.incomplete {
          opacity: 0.25;
          .dmp-swatch {
            border-color: $outlaw-lightgrey !important;
            background: none !important;
          }
        }
        &.done {
          opacity: 0.25;
          .dmp-swatch {
            border-color: $outlaw-lightgrey !important;
            background: $outlaw-lightgrey !important;
          }
        }
        &.activate:hover {
          opacity: 1;
        }
      }
      &.checking {
        .step {
          filter: saturate(0%);
        }
      }
    }

    &.dotvine {
      .steps {
        margin-left: 15px;
        .step {
          margin-right: 20px;
        }
      }
    }

    /* The animation code */
    @keyframes moveUp {
      from {
        bottom: -100px;
      }
      to {
        bottom: 15px;
      }
    }
  }

  //mobile menu with users, comments etc
  .deal-dots {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    height: $mobile-nav-height;
    width: 35px;
  }

  .dd-deal-more {
    right: 0;
  }

  .deal-settings {
    display: flex;
    height: 100%;

    .tooltip-contents {
      .needsReview {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background: #f6b36b;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }

    .settings-button {
      width: 40px;
      position: relative;

      .needsReview {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background: #f6b36b;
        position: absolute;
        right: 2px;
        top: 2px;
      }

      .loader-indidcator {
        position: absolute;
        right: 2px;
        top: 2px;
        margin: 0px !important;

        .dmp-loader-spinner {
          width: 1rem !important;
          height: 1rem !important;
        }
      }

      //to add a colored status dot to ReadyCheck icon
      &.checking {
        &::before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          right: 6px;
          margin-top: -18px;
          z-index: 1;
        }
        &.incomplete:before {
          background: $outlaw;
        }
        &.failed:before {
          background: $alert;
        }
        &.passed:before {
          background: $done;
        }
      }

      &.inactive {
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          width: inherit;
          height: 2px;
          background: $outlaw;
        }
      }

      span {
        display: none;
        @include mediaquery-medium {
          display: inline;
        }
        font-size: 12px;
        font-weight: 700;
      }

      //hidden underlying links with "download" html5 attribute
      //are necessary to create desired auto-download action
      //the visible menu items invoke click() events on these when clicked
      a.docx-hidden,
      a.pdf-hidden {
        display: none;
      }
    }
  }
  $view-mode-dropshadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

  .view-mode {
    display: flex;
    z-index: $layer-fixedbars + 1;
    height: 32px;
    margin-top: calc(#{$mobile-nav-height}/ 2 - 16px);
    margin-left: auto;
    margin-right: auto;

    @include mediaquery-medium {
      &.compact {
        top: -20px;
      }
      margin-top: 0;
      margin-bottom: 0;
      height: 38px;
      margin-left: 0;
      margin-right: 0;
      position: absolute;
      top: calc(#{$deal-header-height} - 20px);
      left: calc(50% - 96px);
      border-radius: 20px;
      box-shadow: $view-mode-dropshadow;
    }

    .view-mode-custom {
      //buttons
      appearance: none;
      //reset to basic style
      background: $white;
      margin: 0;
      padding: 0;
      text-align: center;
      @extend .font_sans;

      //outlaw styled
      width: 80px;
      font-size: 1.2rem;
      border-radius: 16px;
      height: inherit;
      font-weight: 600;
      color: $black90;
      text-shadow: 0 1px 0px $white;
      transition: all 0.2s ease-in;
      box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.05);
      border: 1px solid $black20;

      @include mediaquery-medium {
        border: 1px solid $black10;
        width: 96px;
        font-size: 1.3rem;
        border-radius: 20px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.05);
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 6px;
        border-right: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-right: 6px;
        border-left: 0;
      }

      &.active {
        background: $black10;
        color: $black70;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        pointer-events: none;
      }
      @media (hover: hover) {
        //workaround for mobile to avoid double-tapping
        &:hover {
          background: $black1;
          color: $black100;
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        }
      }
      &:focus {
        outline: none;
      }
    }

    &.dotvine {
      position: absolute;
      height: 32px;
      margin: 0;
      top: calc(#{$deal-header-height-vine} - 16px);
      left: calc(50% - 94px);
      .view-mode-custom {
        width: 84px;
        font-size: 1.2rem;
      }
    }
  }

  // Previous versions show in dark mode so text and icon colors need to be reversed
  &.previous {
    background: $outlaw-dark;

    .toolbar {
      border-bottom: none;
    }

    .btn-back-to-current {
      margin-right: 8px;
      border-right: 1px solid $black60;
    }

    .external-tools {
      border-top: 1px solid $black60;
      border-bottom: none !important;
    }

    .toolbar-group.right,
    .zoom {
      border-left: none;
      border-right: none;
    }

    .previous-header {
      .deal-name {
        color: $white;
      }
      .deal-version {
        font-size: 1.2rem;
        color: $black40;
      }
    }
  }

  &.dotvine {
    top: 0;
    height: $deal-header-height-vine;
    .toolbar {
      box-shadow: none;
    }

    .toolbar-group.right.quick-exports {
      border-left: none;
      .export-as {
        padding-right: 5px;
        color: $black60;
        font-size: 1.2rem;
      }

      .dd-vine-actions-container {
        height: 100%;
        display: flex;
        .dd-vine-actions {
          align-self: center;
          .dmp-btn.btn-default {
            box-shadow: none !important;
            border: none;
          }
          button {
            min-height: 30px;
            min-width: 40px;
            svg {
              width: 15px;
              height: 15px;
            }
          }
          .dropdown-menu {
            .docx {
              a {
                display: flex;
                svg {
                  align-self: center;
                  margin-right: 5px;
                  g {
                    fill: rgb(69, 137, 255);
                  }
                }
              }
            }
            .pdf {
              a {
                display: flex;
                svg {
                  align-self: center;
                  margin-right: 5px;
                  g {
                    fill: rgb(218, 30, 40);
                  }
                }
              }
            }
            .pdfToDocs {
              a {
                display: flex;
                svg {
                  align-self: center;
                  margin-right: 5px;
                  fill: none;
                }
              }
            }
            .docxToDocs {
              a {
                display: flex;
                svg {
                  align-self: center;
                  margin-right: 5px;
                  fill: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

//abc compact is outside of deal header (for iframe integration)
//so needs to be top level
.abc-compact {
  z-index: $layer-abc-compact;
  position: fixed;
  top: auto;
  bottom: 0;
  min-height: 60px;
  padding: 10px 20px;
  width: 100%;
  font-size: 1.2rem;
  color: $white;
  font-weight: 600;
  background-color: $gray-0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
  }

  @include mediaquery-medium {
    font-size: 1.3rem;
  }

  @extend .bigshadow;

  .action {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    // margin-left: 10px;
  }
}

//moved to top-level so that this can be used inside of both ABC and Checkpoint components
.action-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0;
  z-index: 3;
  text-align: center;
  pointer-events: none;

  //also apply button style and animation to post-voting div for ReadyCheck
  .action,
  .reset,
  .voting {
    animation-name: moveUp;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    // animation-delay: .2s;
    position: relative;
    pointer-events: auto;

    // height: 40px;
    // border-radius: 25px;
    // padding: 0 20px;
    margin: 0 auto;
    font-weight: 600;
    min-width: 250px;
    max-width: fit-content;
    text-overflow: ellipsis;
    overflow-x: hidden;
    letter-spacing: 0;
    font-size: 1.3rem;
    padding: 6px 25px;
    box-shadow: 0 5px 10px rgba($black100, 0.3);
    font-size: 1.5rem;
    // height: 70px;
    // width: auto;
    // min-width: 200px;
    border-radius: 8px;
    // margin-bottom: 10px;
    // }

    @include mediaquery-small {
      min-width: 90vw;
      max-width: 90vw;
    }

    @include mediaquery-xsmall {
      min-width: 90vw;
      max-width: 90vw;
    }

    @include mediaquery-medium {
      min-width: 200px;
      max-width: fit-content;
    }
  }

  .voting,
  .passive {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba($outlaw-dark, 0.97);
    border: 1px solid $black10;
    padding: 10px 20px;

    &:has(.btn) {
      .info {
        padding-right: 14px;
      }
    }

    .btn,
    .locked {
      border: none;
      padding: 0;
      margin: 0px 5px;
      &:last-child {
        margin-right: 0px;
      }

      height: 40px;
      width: 40px;
      // border-radius: 25px;
      line-height: 40px;
      text-align: center;

      .icon {
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        svg {
          display: block;
          width: 20px;
          height: 20px;
        }
      }

      &.reject {
        background: $alert;
        box-shadow: inset 0 1px 1px 0 $alert;
        svg path {
          stroke: none;
          fill: $white;
        }
        &.voted-approve {
          background: $black20;
        }
        &:hover {
          background: darken($alert, 2);
        }
        &:focus {
          background: $alert;
        }
      }

      &.approve {
        background: $done;
        box-shadow: inset 0 1px 1px 0 $done;
        svg path {
          stroke: none;
          fill: $white;
        }
        &.voted-reject {
          background: $black20;
        }
        &:hover {
          background: darken($done, 2);
        }
        &:focus {
          background: $done;
        }
      }

      &.stop,
      &.skip,
      &.run,
      &.retry,
      &.contract-action,
      &.preview-action {
        width: auto;
        padding: 0 12px;
      }

      &.skip {
        color: $white;
      }

      &.locked {
        flex-shrink: 0;
        background: $black60;
        box-shadow: inset 0 1px 1px 0 $gray-1;
        svg path {
          stroke: none;
          fill: $white;
        }

        cursor: not-allowed;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .info {
      flex: 1;
      text-align: left;
      .title {
        font-weight: 600;
        font-size: 1.3rem;
        color: $white;
      }
      .sub {
        font-size: 1.3rem;
        font-weight: 400;
        color: $black40;
        &:not(.inactive) {
          cursor: pointer;
          text-decoration: underline;
          &:hover {
            color: $black30;
          }
        }
      }
    }

    &.passive {
      background: rgba($outlaw-dark, 0.95);
      border: none;
      min-width: 200px;
      max-width: fit-content;

      @include mediaquery-small {
        min-width: 90vw;
        max-width: 90vw;
      }

      @include mediaquery-xsmall {
        min-width: 90vw;
        max-width: 90vw;
      }

      @include mediaquery-medium {
        min-width: 200px;
        max-width: fit-content;
      }

      .locked {
        //background: lighten($outlaw-dark, 10%);
      }
      .icon {
        svg {
          path {
            fill: $black40;
          }
          width: 20px;
        }
      }
      .title {
        color: $white;
        font-weight: 700;
      }
      .sub {
        color: $black20;
      }
      .info {
        text-align: left;
      }
    }
  }
}
