.variable {
  position: relative;
  display: inline;

  &.incomplete {
    color: $discuss;
    cursor: pointer;
    padding: 0px 1px 0px 1px;
    border-radius: 0px;
    border: 1px solid transparent;
    border-bottom: 1px dotted $black40;
    &:hover {
      border-bottom-color: $black100;
    }
  }

  &.footnote {
    vertical-align: super;
  }

  &.complete {
    cursor: pointer;
    border-bottom: 1px dotted $black40;

    &:hover {
      border-bottom: 1px dotted $discuss;
    }

    &.attachment {
      border-bottom: none;
      display: block;
    }
  }

  // When the entire deal is in read-only mode
  &.readonly {
    font-weight: 500;
    color: $black40;
    cursor: default;
    border-bottom: 1px dotted $black40;
  }

  &.not-editable {
    font-weight: 400;
    color: inherit;
    border-bottom: none;
    cursor: default;

    &:hover {
      color: inherit;
      border-bottom: none;
      cursor: default;
    }
  }

  &.calculated,
  &.calculated:hover {
    cursor: not-allowed;
    border-bottom: 1px dotted $black40;
  }

  //custom styling is possible for different types of variables
  //reference variables (e.g., "Section 4.2") are overridden
  //to look normal (like a non-variable) because the UX is still TBD
  &.ref:not(.incomplete) {
    font-weight: normal;
    font-family: inherit;
    color: inherit;
    padding: inherit;
  }
  &.term {
    font-weight: normal;
    font-family: inherit;
    color: $discuss;
  }

  &.locked {
    font-weight: normal !important;
    font-family: inherit !important;
    color: inherit !important;
  }

  //for IMAGE (attachment) type variables
  img.attachment {
    max-width: 100%;
  }
  &.attachment img {
    max-width: 100%;
    vertical-align: top;
  }

  &.connected.broken,
  &.calculated.broken {
    @include element($alert);
  }
}

.variable-table-wrapper {
  width: 100%;
  margin-bottom: 30px;

  .table-data {
    width: 100%;
    overflow-wrap: break-word;
  }

  .variable-table {
    width: 100%;
    display: table;
    @extend .font_sans;
    border: 1px solid #d9dee4;
    border-bottom: none;
    cursor: default;
    table-layout: fixed;

    &.no-columns {
      background-color: #fafafa;
      text-align: center;
      padding: 8px 10px;
      border-style: dashed;
    }

    & + .variable-table {
      border-top: none;

      &:empty {
        border: none;
      }
    }

    .column-headers {
      display: table-row;

      .column-controls {
        position: 'relative';
        padding-top: 0;
        top: '-8px';
      }

      .btn-toolbar {
        border-bottom: 1px solid #ccc;
        position: relative;
        left: -10px;
        top: -5px;
        padding: 8px;
        margin-left: 0;
        margin-right: -20px;

        .btn-group {
          width: 100%;
          min-width: 49px;
          white-space: nowrap;
          margin: 0;
        }

        button {
          width: 2em;
          height: 2em;
        }
      }

      & > div:not(.btn-toolbar) {
        display: table-cell;
        font-size: 1.2rem;
        color: $black60;
        font-weight: bold;
        margin-right: 10px;

        padding: 8px 10px;
        border-bottom: 1px solid $gray-3;

        &:not(:last-child) {
          border-right: 1px solid $gray-3;
        }

        .has-tooltip {
          display: flex;
          justify-content: space-between;

          & > div {
            &:nth-child(2) {
              padding-left: 5px;
            }
          }
        }
      }

      .dots {
        width: 24px;
      }

      .title {
        svg {
          fill: #fff !important;
        }
      }
    }

    .table-footer {
      display: flex;
      justify-content: space-between;
      grid-gap: 12px;
      align-items: center;
      position: relative;
      height: 50px;
      padding: 10px;
      border-bottom: 1px solid $gray-3;

      /* Two columns */
      & > * {
        display: flex;
      }

      .help-info {
        display: flex;
        justify-content: space-between;
        grid-gap: 12px;
        align-items: center;

        .message {
          color: #67a0f8;
          font-size: 1.2rem;
          font-weight: 500;
          cursor: default;

          @keyframes fadeOut {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }

          &.hide-message {
            animation: fadeOut 1s forwards;
          }
        }

        .load-diff {
          display: flex;

          svg {
            fill: $gray-0 !important;

            rect {
              fill: transparent !important;
            }
          }

          button {
            &:disabled {
              svg {
                fill: $gray-2 !important;
              }
            }
          }
        }

        .api-status {
          display: flex;
          align-items: center;

          & > * {
            margin-left: 5px;
          }
        }
      }
    }

    .table-row-wrapper {
      position: relative;
      display: table-row;

      & > div,
      & > span,
      .table-row.editing {
        display: table-cell;
        position: relative;
        padding: 10px !important; //To align with headers
        border-right: 1px solid $gray-3;
        border-bottom: 1px solid $gray-3;

        &:last-child {
          border-right: none;
        }

        .icons {
          position: absolute;
          top: 5px;
          right: 5px;

          svg {
            fill: #fff !important;
          }
        }
      }

      &.sync-error {
        .is-dirty {
          background: $color-red-light !important;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray-3;
      }

      &.total-row {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 0;
          border-bottom: 3px solid $gray-3;
        }
      }

      .table-row {
        & > span {
          padding: 10px 5px;
          transition: all 1s;
          position: relative;

          &:first-child {
            padding-left: 10px;
          }

          &:last-child {
            padding-right: 10px;
          }

          &.is-edited {
            background: $color-green-light;
          }

          svg {
            margin-left: -5px;
          }
        }

        .input-wrapper {
          position: relative;

          &.error,
          &.no-sync,
          &.is-dirty {
            input {
              box-sizing: border-box;
            }
          }

          .icons {
            position: absolute;
            top: -6px;
            right: -6px;
          }
        }

        input,
        span {
          flex: 1;
          font-size: 1.2rem;
          margin-right: 10px;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;

          &.is-dirty {
            position: relative;
          }

          &.error {
            color: $alert !important;
          }
        }

        .is-connected.is-calculated {
          background: $gray-5;
          color: $gray-2 !important;
        }
      }

      .table-actions {
        position: absolute;
        right: 0px;
        top: 5px;

        button {
          padding: 0;
        }

        .dd-toggle {
          opacity: 0;
        }
      }
      &:hover {
        .table-actions .dd-toggle {
          opacity: 1;
        }
      }
    }
    .add-row {
      padding: 0;
      text-decoration: underline;
    }
  }
}

.variable-multi-line-text {
  display: grid;
  .option-selection-label {
    position: relative;
    text-decoration: underline solid $black40;
    svg {
      position: absolute;
      bottom: 2px;
    }
  }
  .strike-through-value {
    color: $gray-2;
    text-decoration: underline solid $gray-2;
    text-underline-offset: -40%;
    text-decoration-skip-ink: none;
    //text-decoration: line-through solid $gray-2;
  }

  .multi-line-label {
    color: $gray-2;
  }
}

.image-uploader {
  // Avoid bleeding out and creating an overflowX
  input[type='file'] {
    width: 100%;
  }
}
.image-uploader-inner {
  position: relative;

  .remove-image {
    position: absolute;
    width: 21px;
    height: 21px;
    line-height: 20px;
    font-size: 19px;
    background: $black80;
    border-radius: 50%;
    border: 1px solid $white;
    top: 6px;
    left: 4px;
    color: $white;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    @extend .font_sans;
  }

  .preview,
  .upload {
    border: 1px solid $black20;
    width: 100%;
    height: 150px;
    border-radius: 3px;
    background-color: $black2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    img {
      //logo inherits size of outer div
      padding: 5px;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .upload {
    cursor: pointer;
    background-image: url(/assets/svg/icon-logo-upload.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;
    border: 1px dashed $black20;

    h4 {
      margin-bottom: 60px;
    }
    small {
      text-align: center;
    }

    &:hover {
      background-color: $white;
      border-color: $black30;
    }
  }

  .error {
    color: $alert;
    position: absolute;
    left: 0;
    bottom: -34px;
  }
}

.variable-defining,
.variable.raw {
  color: $black40;
  font-weight: 500;
  background: $black3;
  padding: 2px;
  border-bottom: none !important;
}

.popover-variable-view {
  .variable-connector {
    margin-bottom: 5px;
    ul.dropdown-menu {
      width: 100%;
    }

    .config-ds {
      position: relative;
      margin-bottom: 5px;
      .link-select {
        @extend .clearLink;
      }
    }
  }
  textarea.variable-value {
    height: 80px;
  }

  .warning {
    font-size: 1.2rem;
    line-height: 15px;
    color: $alert;
    margin-top: 3px;
  }
  .actions {
    display: flex !important;
    align-items: center;
    .spacer {
      flex: 1;
    }
    .save {
      margin-left: 8px;
    }
  }
  .multiline-selection-dropdown {
    margin-bottom: 10px;
  }

  .close-popover {
    margin-top: -4px;
  }
}

.variable-multiple-value {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: $gray-5;
  }
  max-height: 350px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 4px 8px;
  height: auto;
  .radio {
    margin: 0px;
    accent-color: $gray-1;
  }
  .dmp-checkbox {
    overflow-wrap: anywhere;
  }
  .multi-line-label {
    color: $gray-2;
  }
}

.transform-menu-options,
.address-properties-menu-options {
  ul {
    width: 100%;
  }
}

// popover in template editing for variable definition
.popover-footnote-editor,
.popover-variable-editor,
.popover-custom-numbering,
.popover-column-editor,
.popover-step-editor,
.popover-lens-editor {
  min-width: 220px;
  border: 1px solid $black10;
  .popover-title {
    padding: 0 0 15px;
  }

  .variable-connector {
    margin-bottom: 10px;
    ul.dropdown-menu {
      width: 100%;
    }
    .alert-resolve {
      background-color: #fef9ef;
      border-color: #f6b36b;
      color: #f6b36b;
    }
  }

  .dmp-validator-container {
    &.formula {
      .dmp-alert-content {
        overflow: hidden;
        word-wrap: break-word;
      }
    }
  }

  .dmp-switch {
    margin-top: 5px;
  }

  textarea.variable-value {
    height: 80px;
    max-width: 240px;
    max-height: 100px;
  }

  .alert {
    margin-bottom: 10px;
    word-break: break-word;
  }

  .display {
    .error {
      border-color: $color-red;
      color: $color-red;

      &::placeholder {
        color: $color-red;
        opacity: 1; /* Firefox */
      }
    }
  }

  //for CustomNumbering popover component
  .number-style {
    display: flex;
    input {
      flex: 1;
    }
    .dropdown {
      margin: 0 8px;
      flex: 3;
      .dropdown-toggle {
        width: 100%;
      }
    }
  }
  .number-example {
    padding: 6px 0;
    border-top: 1px solid $black10;
    border-bottom: 1px solid $black10;
    text-align: center;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }

  input,
  select,
  .field {
    margin-bottom: 4px;
    &.val-type {
      min-height: 30px;
    }
  }
  select {
    text-transform: capitalize;
  }
  .actions {
    text-align: right;
    margin-top: 20px;
  }
  .col-prompt textarea {
    min-height: 100px;
  }

  //when REF var is shown in popup, show text input and section selector on same line
  .REF {
    .field {
      float: left;
      width: 47.5%;
      &:first-child {
        margin-right: 5%;
      }
    }
  }

  .protected {
    .default {
      position: relative;
      .dmp-loader {
        top: 5px;
      }
    }
  }

  .element-name {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black80;
    &:not(.connected) {
      cursor: pointer;
      text-decoration: underline;
    }
    &.FOOTNOTE {
      cursor: none;
      text-decoration: none;
    }
  }

  .form-group .control-label {
    font-size: 1.2rem;
  }

  .variants {
    .extraction-type {
      margin-top: 2px;
      .radio-container {
        display: flex;
        flex-direction: row;
        .radio {
          margin-top: 2px;
          margin-bottom: 2px;
        }
        svg {
          path {
            fill: $black40;
          }
          &:hover {
            path {
              fill: $black80;
            }
          }
          vertical-align: middle;
          margin-top: 4px;
          margin-left: 3px;
        }
      }
    }
    .extract-container {
      display: flex;

      .extract-icon {
        height: 15px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        margin-top: 5px;
        color: $gray-0;

        svg {
          margin-right: 5px;
          margin-top: -2px;
          vertical-align: middle;
          path {
            fill: $gray-0 !important;
          }
        }
      }

      .extract-switch {
        margin-top: 3px !important;
        margin-left: auto;
      }
    }
  }

  .columns-prompt {
    margin-top: -10px;
    .control-label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      span {
        flex: 1;
      }
      button {
        padding: 0;
        font-weight: normal;
      }
    }
    textarea {
      min-height: 100px;
    }
  }

  .columns-edit {
    display: flex;
    background-color: #252f3d;
    font-size: 1.1rem;
    @extend .font_sans;
    color: $white;
    padding: 1px 10px;
    margin-top: -1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); //To match TextArea
    align-items: center;

    @at-root .edit-actions .columns-edit:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &.error,
    &.warning {
      padding: 5px;
      display: flex;

      svg {
        align-self: flex-start;
        min-width: 15px;
        height: auto;
      }

      & + .columns-edit {
        margin-top: 0;
      }
    }

    &.error {
      background: rgba($alert, 0.05);
      color: $alert;

      svg {
        fill: $alert;
      }
    }

    &.warning {
      background: $color-orange-light;
      color: $color-orange;

      svg {
        fill: $color-orange;
      }
    }

    .columns-edit-save {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      button {
        padding: 0px;
        font-weight: 400;
        color: $white;

        &:hover {
          color: darken($white, 20%);
        }
        &.save-columns {
          text-decoration: none;
          font-weight: 800;
          padding-left: 5px;
        }
      }
    }
  }

  .columns.connected {
    margin-top: -10px;
    .control-label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      span {
        flex: 1;
      }
      button {
        padding: 0;
        font-weight: normal;
      }
    }
    textarea {
      min-height: 100px;
    }
  }

  //for some reason action button block was obscuring click events
  //this fixes it
  .actions {
    clear: both;
    position: inherit;
  }

  .formula {
    position: relative;
    .variable-suggest {
      top: inherit !important;
      left: inherit !important;
    }
    .dmp-validator {
      right: 3px;
      top: 33px;
    }
  }

  // overrides/fixes for step editor
  &.popover-step-editor {
    .step-title input {
      margin-bottom: 0;
    }
    .control-label {
      padding: 0;
      margin-bottom: 0;
    }
    .actions {
      margin-top: 5px !important;
    }
  }

  &.popover-lens-editor {
    .dmp-dd {
      width: 100%;
    }
  }
}

.url {
  text-decoration: underline;
  text-decoration-color: $black40;
  cursor: pointer;
}

.radio-group {
  .radio {
    margin: 0;
  }
}
