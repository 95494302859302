/*
  DMP COMPONENT: BUTTON
*/

$dmp-button: '#{$dmp}-btn';

.#{$dmp-button} {
  font-family: $font-family-base;
  cursor: pointer;

  &.btn:focus {
    outline: none;
  }

  // box-shadow styling, not themable
  &.btn-default {
    box-shadow: inset 0px -2px 0px rgba(212, 219, 228, 0.5);
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
  &.btn-primary {
    box-shadow: inset 0px -2px 0px rgba(darken($outlaw, 50%), 0.25);
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
  &.btn-danger {
    box-shadow: inset 0px -2px 0px rgba(darken($btn-danger-bg, 50%), 0.25);
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-filevine {
    box-shadow: inset 0px -2px 0px rgba(darken($filevine-primary, 50%), 0.25);
    background-color: $filevine-primary;
    color: $white;

    &:active,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: darken($filevine-primary, 5%);
    }
  }

  // Adjust @dmp/<Loader> inside the button
  .dmp-loader {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px 0 0;
    border-color: currentColor;
  }

  &-no-padding {
    padding: 0;
  }

  /*
    Icon Button
  */
  &-icon {
    justify-content: center;

    &-link {
      // Make sure that our -icon-only does not overwrite this
      width: auto !important;
      height: auto !important;

      // Make sure that we remove the styling that bootstrap is adding from the addition of .btn
      margin: 0;
      padding: 0;
      border: 0;
      &:active,
      &.active {
        box-shadow: none;
      }
    }

    display: flex;
    align-items: center;

    .dmp-i {
      fill: $gray-1;
      // By default, add margin-right to the icon so that text can adjust
      margin-right: 5px;

      &[data-icon='syncEnabled'] {
        rect {
          fill: transparent;
        }
      }
    }

    // If it's icon-only, remove the margin and slightly reduce the padding.
    &-only:not(.dmp-dd-toggle) {
      width: 39px;
      height: 39px;
      padding: 0;

      .dmp-i {
        margin-right: 0;
      }
    }

    &-disabled {
      opacity: 0.4 !important;
      cursor: not-allowed !important;
    }

    // Dark
    .dmp-i-dark {
      fill: $gray-2;
    }

    // Hover states
    &:hover:not([disabled]),
    &:active:not([disabled]),
    &.active:not([disabled]) {
      .dmp-i {
        fill: $gray-0;
      }
      .dmp-i-dark {
        fill: $gray-4;
      }

      &.#{$dmp-button}-primary,
      &.#{$dmp-button}-danger,
      &.#{$dmp-button}-filevine {
        .dmp-i {
          fill: $white;
        }
      }
    }

    // Special "tool" style to accommodate the newer toolbar buttons in Draft
    // This may be covered already but I didn't want to unintentionally conflict with existing styles
    &.dmp-btn-tool {
      min-width: 30px;
      min-height: 30px;
      margin-right: 1px;

      &.active {
        background: $gray-3;
        border-radius: 3px !important;
      }

      &:hover:not([disabled]):not(.active) {
        background: $gray-4;
      }

      &.dmp-btn-large {
        min-width: 39px;
        min-height: 39px;
      }

      &.#{$dmp-button}-dark {
        &.active {
          background: $gray-1;
          border-radius: 3px !important;
        }

        &:hover:not([disabled]):not(.active) {
          background: $gray-1;
        }
      }
    }

    &-checkbox {
      &:hover:not([disabled]),
      &.#{$dmp-button}-icon-checked {
        .dmp-i-dark {
          border-radius: 3px;
          border: none;
          fill: $white;
        }
      }
    }
  }

  /*
    Sizes
  */
  &-xsmall {
    &.#{$dmp-button}-icon-only:not(.dmp-dd-toggle) {
      width: 20px;
      height: 20px;
    }
  }

  &-small {
    &.#{$dmp-button}-icon-only:not(.dmp-dd-toggle) {
      width: 30px;
      height: 30px;
    }
  }

  /*
    Styles
  */
  &-primary,
  &-danger,
  &-filevine {
    .dmp-i {
      fill: $white;
    }
  }

  /*
    Link Buttons
    Note: we need to enforce the .dmp-btn-link rule because Bootstrap uses .btn.btn-link
  */
  &.#{$dmp-button}-link.btn {
    color: $gray-0;

    font-weight: 600;
    text-decoration: underline;

    &.#{$dmp-button}-link-danger {
      color: $brand-danger;
      .dmp-dd-toggle-caret {
        fill: $brand-danger;
      }
    }
    &.#{$dmp-button}-link-info {
      color: $color-blue;
      .dmp-dd-toggle-caret {
        fill: $color-blue;
      }
    }
    &.#{$dmp-button}-link-primary {
      color: $outlaw;
      .dmp-dd-toggle-caret {
        fill: $outlaw;
      }
    }
    &.#{$dmp-button}-link-success {
      color: $color-green;
      text-decoration: none;

      &:hover {
        color: $color-green;
        text-decoration: underline;
      }

      .dmp-dd-toggle-caret {
        fill: $color-green;
      }
    }
    &.#{$dmp-button}-link-reject {
      color: $color-red;
      text-decoration: none;

      &:hover {
        color: $color-red;
        text-decoration: underline;
      }

      .dmp-dd-toggle-caret {
        fill: $color-red;
      }
    }

    &:hover,
    &:active {
      color: $gray-dark;
      .dmp-dd-toggle-caret {
        fill: $gray-dark;
      }
    }

    &.#{$dmp-button}-no-underline {
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &-dark {
    // Adding specificity because of Bootstrap
    &.btn.btn-link {
      color: $gray-2;
      .dmp-i {
        fill: $gray-2;
      }

      &:hover {
        color: $gray-4;
        .dmp-i {
          fill: $gray-4;
        }
      }

      &:focus,
      &:active {
        color: $white;
        .dmp-i {
          fill: $white;
        }
      }
    }
  }

  // Disabled states
  // There must always be defined after to overwrite custom styles above
  &[disabled] {
    &.btn.#{$dmp-button} {
      cursor: not-allowed;
      opacity: 1;
      filter: none;
      box-shadow: none;

      color: $gray-2;
      .dmp-i {
        fill: $gray-2;
      }

      &.active {
        background: $gray-3 !important;
      }

      &-default {
        background-color: $gray-5;
        border-color: $gray-3;
      }

      &-primary,
      &-danger,
      &-filevine {
        background-color: $gray-3;
        border-color: transparent;
        color: $gray-2;

        .dmp-i {
          fill: $gray-2;
        }
      }

      &-link,
      &-link-primary,
      &-link-danger,
      &-link-success {
        &,
        &:hover,
        &:active,
        &:focus {
          color: $gray-2;
          .dmp-i {
            fill: $gray-2;
          }
        }
      }

      &-no-underline {
        &:hover,
        &:active {
          text-decoration: none;
        }
      }

      // Disabled dark style
      &-dark {
        color: $gray-1;
        .dmp-i {
          fill: $gray-1;
        }

        &.#{$dmp-button}-link,
        &.#{$dmp-button}-link-primary,
        &.#{$dmp-button}-link-danger {
          &,
          &:hover,
          &:active,
          &:focus {
            color: $gray-1;
            .dmp-i {
              fill: $gray-1;
            }
          }
        }
      }
    }
  }

  /*
    Close Button
  */
  &-close {
    .dmp-i {
      width: 16px;
      height: 16px;
    }

    .dmp-i path {
      fill: #8795a5;
    } // $gray-2
  }
}
