//
//
// reusable items
//
//
$entity-protected-color: $done;

input,
textarea {
  border: 1px solid $black20;
  @include font-sizing(medium);
  padding: 20px 10px;
  color: $black80;
  &:focus {
    border-color: $black40;
  }
}
//flexbox tables used in admin
.table-outer {
  @extend .papershadow;
  border-radius: 3px;

  .table-inner {
    background: $white;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    //take off last border
    border-bottom: 1px solid $black10;
    &:first-child {
      border-top-left-radius: 3px !important;
      border-top-right-radius: 3px !important;
    }
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }
    //form-group fixes
    &.form-group {
      margin-bottom: 0;
    }
    .block {
      display: block;
    }
    //-------------------------------
    //flex styling
    .table-label {
      flex: 1;
      font-size: 1.2rem;
      font-weight: 500;
    }
    .table-info {
      flex: 3;
    }
    .table-actions {
      flex: 1;
      text-align: right;
    }
    .s-mob-full {
      flex: 1;
    }
    .s-mob-half {
      flex-direction: row;
      flex: 1;
    }
    .s-mob-onethird {
      flex: 1;
    }
    .s-mob-twothird {
      flex: 2;
      //this is used on TemplateListItems
      //descriptions were running right up to the status tag
      padding-right: 15px;
    }
    .s-tab-onethird {
      @include mediaquery-medium {
        flex: 1;
      }
    }
    .s-tab-twothird {
      @include mediaquery-medium {
        flex: 2;
      }
    }
    .s-tab-half {
      @include mediaquery-medium {
        flex: 1;
      }
    }
    .align-top {
      align-self: flex-start;
    }
    .statusLabel {
      top: 0;
    }

    img.team-logo {
      max-height: 60px;
      max-width: 120px;
    }

    img.team-letterhead {
      max-height: 60px;
      max-width: 120px;
    }
  }
}

.setting-block-members {
  padding-left: 20px;
  padding-right: 20px;

  .search-users {
    width: 300px;
  }
  .add-button {
    margin-left: 10px;
  }

  .error {
    color: $alert;
  }
}

.setting-block {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  clear: left;
  padding-left: 20px;
  padding-right: 20px;
  @include mediaquery-medium {
    flex-direction: row;
    padding: 0;
    padding-bottom: 40px;
  }
  .block-header,
  .block-content {
    width: inherit;
    display: block;
    margin-bottom: 10px;
    @include mediaquery-medium {
      float: left;
    }
    .utility {
      text-align: right;
      margin-bottom: 15px;
    }
  }
  .block-header {
    //left hand side (title and caption)
    @include mediaquery-medium {
      width: 25%;
      padding-right: 30px;
    }
    h4 {
      padding-bottom: 5px;
      color: $black80;
    }
    .prompt {
      color: $black60;
    }
  }
  .block-content {
    //right hand side content
    @include mediaquery-medium {
      width: 75%;
    }
  }
  small {
    @include mediaquery-medium {
      padding-right: 30px;
    }
  }
  &.ai-config {
    .utility.spacer { height: 40px; }
    .ai-settings .table-inner {
      display: block;
    }
    .switch-container {
      .sw-info {
        font-size: 1.3rem;
        color: $black60;
        display: block;
        padding-top: 3px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .rules-container {
      max-height: 460px;
      overflow: scroll;
      .react-json-view {
        padding: 5px;
      }
    }
    .btn-delete-rules {
      margin-top: 10px;
    }
  }
}
//
//
// end shared styles
//
//
.upload-logo {
  display: flex;
  flex-direction: row;
  .empty-box {
    width: 40px;
    height: 40px;
    background-image: url(/assets/svg/icon-logo-none.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .empty-details {
    flex: 1;
  }
}
.billing {
  .overview {
    display: flex;
    align-content: flex-end;
    flex-direction: row;
    h3,
    small {
      padding: 0;
      margin: 0;
    }
    h3 {
      line-height: 30px;
    }
    small {
      line-height: 20px;
    }
  }
  .subscription,
  .discount {
    .summary {
      padding-right: 20px;
    }
    .date {
      font-size: 1.2rem;
      color: $black40;
    }
  }
  .discount {
    .table-info {
      flex: 4;
    }
    .summary {
      color: $outlaw;
    }
  }
  .creditcard {
    small {
      display: block;
      span {
        font-weight: bold;
      }
    }
  }
  .paid {
    //style differently for Teams plan
    .overview {
      //section at the top of box
      background-color: #6231af;
      background-image: url(/assets/png/subscription.png);
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
      h3,
      small {
        color: $white;
      }
    }
  }

  .history {
    .col {
      &:not(:last-child) {
        padding-right: 20px;
      }
      &:last-child {
        text-align: right;
      }
    }
    .col-date {
      flex: 1;
    }
    .col-desc {
      flex: 3;
    }
    .col-amount {
      flex: 1;
    }
    .col-status {
      flex: 1;
    }
  }
}

.card-info {
  // modal for upgrading plan
  .upgrade-to {
    display: flex;
    flex-direction: row;
    height: 120px;
    padding: 0 20px;
    align-items: center;
    border-bottom: 1px solid $black10;

    .plan-details {
      flex: 3;
      h3 {
        margin: 0;
      }
      .current-seats {
        color: $outlaw;
      }
      &:first-child {
        padding-right: 20px;
      }
    }
    .plan-price {
      //pricing
      display: flex;
      flex-direction: row;
      flex: 1;
      text-align: right;
      justify-content: flex-end;

      .quantity,
      .price {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
      }
      .x {
        margin-left: 20px;
        margin-top: 7px;
      }
      .price {
        width: 110px;
        .amount {
          color: $outlaw;
          .dollar-sign {
            font-family: 'Helvetica Neue';
            font-size: 1.5rem;
            vertical-align: top;
          }
          .cost {
            font-family: 'HelveticaNeue';
            font-weight: 400;
            font-size: 34px;
            line-height: 36px;
          }
        }
      }

      .seats,
      .per-seat {
        margin-top: 5px;
      }
    }
  }

  .interval {
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    align-items: center;
    border-bottom: 1px solid $black10;

    .frequency {
      font-weight: 500;
      flex: 1;
    }
    .buttons {
      justify-content: flex-end;
      display: flex;

      .interval-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 50px;
        @include mediaquery-medium {
          width: 120px;
          height: 60px;
        }
        cursor: pointer;
        border-radius: 3px;
        border: 2px solid $black10;
        &.selected {
          border-color: $outlaw;
        }
        &:first-child {
          margin-right: 10px;
        }

        .interval-label {
          color: $black60;
        }
        .savings {
          color: $outlaw;
          font-size: 1.1rem;
        }
      }
    }
  }

  .totals {
    border-bottom: 1px solid $black10;
    padding: 15px 20px;
    .total-row {
      display: flex;
      flex-direction: row;
      padding: 5px 0;
      justify-content: center;
      .row-label {
        // font-weight: 500;
        flex: 3;

        .new-sub,
        .pay-now {
          font-weight: 500;
        }

        .sub-label {
          font-size: 1.2rem;
          font-weight: normal;
          color: $black40;
        }
      }
      .row-value {
        align-content: flex-end;
        position: relative;
        img.loader {
          // displayed while pricing is updating inside h4 tag
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0.5;
        }
      }

      &.discounted {
        .row-value {
          color: $black40;
          text-decoration: line-through;
        }
      }
      &.coupon {
        font-weight: 600;
        color: $outlaw;
      }
    }
  }

  .current-card {
    border-bottom: 1px solid $black10;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    .on-file {
      flex: 3;
      font-weight: 500;
    }
    .card-info {
      align-content: flex-end;
      display: flex;
      flex-direction: column;
      text-align: right;
      a {
        cursor: pointer;
        font-weight: normal;
        color: $outlaw;
        &:hover {
          color: $outlaw;
          font-weight: normal;
          text-decoration: underline;
        }
      }
    }
  }

  //overrides
  //find out where
  .field-block {
    border-bottom: 0;
    padding: 0;
    // margin-top: 20px;
    // @include mediaquery-medium { padding: 0 20px; }
    .fields {
      display: block;
      width: 100%;
      padding-left: 0;
    }
  }

  .roadblock {
    padding: 20px 20px 0;
    color: $outlaw;
    font-size: 1.7rem;
    font-weight: 600;
  }
  .access {
    padding: 20px;
  }

  .payment-info {
    background: $black2;
    padding: 20px;
    // border-top: 1px solid $black10;
    border-bottom: 1px solid $black10;

    @include mediaquery-medium {
      border-left: 1px solid $black10;
      border-right: 1px solid $black10;
    }

    h4 {
      margin-bottom: 10px;
    }
  }
  .card-name,
  .card-number,
  .card-month,
  .card-year,
  .card-cvc,
  .card-zip {
    float: left;
  }
  .card-name,
  .card-number {
    width: 100%;
    @include mediaquery-medium {
      width: 48%;
      &:first-child {
        margin-right: 4%;
      }
    }
  }
  .card-month {
    width: 17%;
    margin-right: 2%;
  }
  .card-year {
    width: 17%;
    margin-right: 4%;
  }
  .card-cvc {
    width: 28%;
    margin-right: 4%;
  }
  .card-zip {
    width: 28%;
    margin-right: 0;
  }

  .modal-footer img.loader {
    position: absolute;
    left: 20px;
    bottom: 15px;
    width: 40px;
  }

  .payment-error {
    color: $alert;
    font-size: 1.2rem;
    margin-top: 10px;
  }

  //inline mode has slightly different padding
  &.inline {
    .number-users {
      font-weight: 500;
    }

    .upgrade-to,
    .interval,
    .totals,
    .modal-footer {
      padding-left: 15px;
      padding-right: 15px;

      @include mediaquery-medium {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .modal-footer {
      padding-top: 15px;
      text-align: right;
      border-top: none;
      img.loader {
        left: 0;
      }
    }
  }
}

.team {
  .flex-with-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .invited {
    color: $gray-1;
  }

  .table-inner-disabled {
    color: $black40;
  }
  .tag {
    display: inline-block;
    padding: 5px 10px;
    font-size: 9px;
    font-weight: 700;
    line-height: 9px;
    text-transform: uppercase;
    border-radius: 2px;
    border-color: transparent;
    position: relative;
    border-width: 1px;
    margin-left: 10px;
    &.admin {
      background-color: $primary-light;
      color: $outlaw;
    }
    &.partner {
      background-color: rgb(209, 250, 232);
      color: #46ca8c;
    }
    &.billing {
      background-color: $color-blue-light;
      color: $color-blue;
      cursor: help;
    }
  }

  .integrations {
    .message {
      max-width: 350px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .table-inner {
      align-items: center;
    } //centering fix
  }

  .okta-integration {
    b {
      color: #333;
      display: block;
    }
  }
}

.add-team-member {
  .contents {
    .dropdown {
      width: 100%;
      button,
      ul {
        width: 100%;
      }
    }
    .observer-role-title {
      padding-bottom: 6px;
    }
  }

  .observer {
    padding-top: 6px;
  }
}

.add-team-member,
.team-creator,
.theme-info,
.scoring-editor,
.modal-apiKey-info,
.modal-okta-integration,
.modal-filevine-integration,
.workflow-editor,
.user-editor,
.team-editor {
  .modal-body {
    padding: 20px !important;

    p {
      font-size: 1.3rem !important;

      &.error {
        color: $alert;
      }
    }

    .user {
      .id {
        font-size: 12px;
        color: #8795a5;
      }
    }
  }
  .exceeding {
    @include font-sizing(x-small);
    position: absolute;
    text-align: left;
    color: $black40;
    a {
      cursor: pointer;
      text-decoration: underline;
      color: $discuss;
    }
  }
  textarea {
    resize: none;
  }

  span.copied {
    position: absolute;
    margin-top: -73px;
    right: 66px;
    background: $outlaw;
    color: white;
    font-size: 1.1rem;
    padding: 1px 5px;
    border-radius: 3px;
  }

  .apiKey-teamID {
    padding-top: 7px;
    color: $black60;
  }

  .apiKey-secret {
    text-overflow: ellipsis;
  }

  .apiKey-desc {
    margin-top: 10px;
  }

  .copy-button {
    max-height: 40px;
  }

  .modal-footer {
    margin-top: 0px !important;
  }

  .radio-button-group {
    display: flex;
    flex-direction: row;
  }

  .radio + .radio {
    margin-top: 10px;
    margin-left: 10px;
  }

  .radio {
    accent-color: $gray-1;
  }

  .divider {
    position: relative;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-bottom: 10px;
    left: -20px;
    width: calc(100% + 40px);
  }

  .scoring-details {
    width: 100%;

    .entry {
      display: flex;
      flex: row;
      border-bottom: 1px solid rgb(229, 229, 229);
      padding-bottom: 10px;

      .form-group {
        flex-direction: column;
        margin-bottom: 4px;
      }

      .control-label {
        font-size: 1.4rem;
        color: $gray-1;
        font-weight: 600;
        flex-basis: initial;
      }

      .grade {
        flex: 4;
        margin-left: 20px;

        .grade-input {
          margin-bottom: 4px;
        }

        .description {
          margin-top: -4px;
        }
      }

      .delete-entry {
        flex: 1;
        height: 39px !important;
      }

      .scoring-filter {
        flex: 4;
        .values {
          display: flex;
          align-items: center;
          input {
            margin-bottom: 0;
          }
          .and {
            margin: 0 5px;
            margin-bottom: 5px;
          }
          margin-bottom: 4px;
        }
        .pair {
          display: block;
        }
      }

      .dmp-dd-toggle-error {
        border-color: $state-danger-text;
      }
    }

    .add-grade {
      margin-top: 10px;
      svg {
        vertical-align: middle;
        path {
          fill: #8795a5;
        }
      }
      .dmp-btn-link {
        color: #8795a5;
      }
    }
  }
}

.modal-webhook-tester {
  .modal-dialog {
    width: 780px;
    max-width: 90%;

    .modal-body {
      pre {
        max-height: 600px;
      }
    }
  }
}

.checkpointGroup-editor {
  .modal-body {
    padding: 20px !important;

    p {
      font-size: 1.3rem !important;

      &.error {
        color: $alert;
      }
    }
  }
  .exceeding {
    @include font-sizing(x-small);
    position: absolute;
    text-align: left;
    color: $black40;
    a {
      cursor: pointer;
      text-decoration: underline;
      color: $discuss;
    }
  }
  textarea {
    resize: none;
  }

  span.copied {
    position: absolute;
    margin-top: -73px;
    right: 66px;
    background: $outlaw;
    color: white;
    font-size: 1.1rem;
    padding: 1px 5px;
    border-radius: 3px;
  }

  .apiKey-teamID {
    padding-top: 7px;
    color: $black60;
  }

  .apiKey-secret {
    text-overflow: ellipsis;
  }

  .apiKey-desc {
    margin-top: 10px;
  }

  .copy-button {
    max-height: 40px;
  }

  .modal-footer {
    margin-top: 0px !important;
  }

  .radio-button-group {
    display: flex;
    flex-direction: row;
  }

  .radio + .radio {
    margin-top: 10px;
    margin-left: 10px;
  }

  .radio {
    accent-color: $gray-1;
  }

  .participants-selector {
    display: flex;
    width: 100%;
    position: relative;

    //teammate selector is on the left hand side
    .teammate-selector {
      height: 260px;
      border-right: 0px;
      border-top: 0px;

      .teammates-list {
        height: calc(100% - 16px);
      }
    }

    .participants {
      height: 260px;
      flex-basis: 260px;
      background-color: $black2;
      border: 1px solid $black10;
      border-radius: 1px;
      flex: 1;
      overflow: hidden;

      .col-headers {
        background: $white;
        height: 30px;
        display: flex;
        flex-direction: row;
        padding: 0 40px 0 10px;
        align-items: center;
        border-bottom: 1px solid $black10;
        justify-content: space-between;
        label {
          margin-bottom: 0;
          font-size: 1.2rem;
          font-weight: 600;
          color: $black40;
        }
        &:first-child {
          flex: 1;
        }
      }
      .participants-list {
        height: calc(100% - 32px);
        overflow-y: scroll;
      }
    }
  }
}

.theme-info {
  .dmp-checkbox {
    margin-top: 5px;
  }
}

.team-creator {
  .create-team {
    img {
      width: 25px;
      display: inline-block;
      margin-right: 10px;
    }
    text-align: right;
  }
  .dropdown-error {
    border: solid 1px $color-red;
    border-radius: 4px;
  }
  .modal-footer img.loader {
    position: absolute;
    left: 20px;
    bottom: 15px;
  }

  .address-input {
    display: block;
    width: 100%;
    flex: 1;
  }
}

.sortable {
  .dmp-tag {
    margin-right: 0;
    align-items: center;
  }
}

.workflow-editor {
  .dmp-tag {
    margin-right: 0;
    align-items: center;
    .dmp-drag-handle {
      margin-left: -10px;
      margin-top: -1px;
    }
    .step-title {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .add-workflow-step {
    position: relative;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      width: 100%;
      height: 0;
      border-bottom: 1px dashed $black10;
      z-index: -1;
    }
  }
  .title-color {
    display: flex;
    flex-direction: row;
    .step-title {
      flex: 3;
      margin-right: 8px;
    }
    .step-color {
      flex: 1;
      .color-picker {
        z-index: 1;
      }
    }
  }
}
